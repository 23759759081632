import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import moment, {Moment} from "moment";

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  constructor(private translateService: TranslateService) {
    super();
  }

  parse(value: string): NgbDateStruct {

    if (value) {
      const dateFormat = this.translateService.instant('common.moment-date-format') ?? 'YYYY-MM-DD';

      const date: Moment = moment(value, dateFormat)
      return {day: date.date(), month: date.month() + 1, year: date.year()};

    }
    return null;
  }

  format(date: NgbDateStruct): string {

    if (!date) {
      return ''
    }
    const dateFormat = this.translateService.instant('common.moment-date-format') ?? 'YYYY-MM-DD';
    const momentDate: Moment = moment()
    momentDate.month(date.month-1);
    momentDate.date(date.day);
    momentDate.year(date.year);
    return momentDate.format(dateFormat.toUpperCase());
  }


}
